import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import thunk from 'redux-thunk';
import { createSocketMiddleware } from '../utils/socket';
import rootReducer from './reducers';
import baseApi from '../api/baseApi';

const initialState = {};

const middleware = [thunk, createSocketMiddleware(), baseApi.middleware];

const store = createStore(rootReducer, initialState, composeWithDevTools(applyMiddleware(...middleware)));

export default store;
