import React, { useMemo, useState } from 'react';
import Swal from 'sweetalert2';
import MacroSection from '../../MacrosSection/MacrosSection.jsx';
import { setChatScroll } from '../../../../../actions/chatActions.js';
import { useDispatch, useSelector } from 'react-redux';
import { setSocketUsers } from '../../../../../actions/socketActions.js';
import { setMacroModalStatus } from '../../../../../actions/macroActions.js';
import sendIcon from '../../../../../assets/img/send.svg';
import dismissIcon from '../../../../../assets/img/dismiss.svg';
import flashIcon from '../../../../../assets/img/flash.svg';
import addIcon from '../../../../../assets/img/add.svg';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import './ChatSectionFooter.scss';
import useChatFiles from '../../../../../hooks/useChatFiles';
import ChatSectionFilePreview from './ChatSectionFilePreview.jsx';
import { getAttachmentType, getImageDimensions } from '../../../../../global/Helpers.js';
import { userSelector, socketSelector, authSelector } from '../../../../../selectors/mainSelectors.js';
import {
  useSendInternalMessageMutation,
  useSendStreamMessageMutation,
  useUploadFileMutation
} from '../../../../../api/messageApi.js';
import { isNil } from 'lodash';
import defaultCaptureException from '../../../../../utils/sentryUtils.js';
import { BOOKKEEPER_ACCOUNT_TYPES } from '../../../../../constants.js';

const ChatSectionFooter = () => {
  const dispatch = useDispatch();
  const [message, setMessage] = useState('');

  const [sendInternalMessage] = useSendInternalMessageMutation();
  const [uploadFile] = useUploadFileMutation();
  const [sendMessage] = useSendStreamMessageMutation();

  const user = useSelector(userSelector);
  const socket = useSelector(socketSelector);
  const auth = useSelector(authSelector);

  const { stagedFile, setStagedFile, openFileSelector, onClose, clear, plainFiles, filesContent } = useChatFiles();
  const { memberInfo } = user;

  const setMacroMessage = (macroMessage) => {
    setMessage(message + ' ' + macroMessage);
  };

  const internalOnly = useMemo(
    () => message.length === 0 || auth?.user?.accountType === BOOKKEEPER_ACCOUNT_TYPES.INTERNAL_TAX_PRO,
    [message, auth]
  );

  const fileToUpload = useMemo(() => {
    const fileType = plainFiles[0]?.type;
    const fileContent = filesContent[0]?.content;
    if (!fileType || !fileContent) {
      return null;
    }
    return { type: fileType, data: fileContent?.split(',')[1], imageUrl: fileContent };
  }, [plainFiles, filesContent]);

  const handleSendChatMessage = async (e, phone) => {
    e.preventDefault();
    const memberPhone = memberInfo.phone;
    if (String(memberPhone) !== String(phone)) {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: `Please reselect member.`
      });
      return;
    }

    const { users } = socket;
    const filteredUsers = users.filter((u) => u !== phone);

    try {
      dispatch(setSocketUsers(filteredUsers));

      // Upload file and format attachment for message if file exists
      const getAttachments = async () => {
        if (!isNil(fileToUpload)) {
          const response = await uploadFile({ ...fileToUpload, user_id: memberInfo.id });
          if (!isNil(response.data)) {
            const dimensions = await getImageDimensions(fileToUpload);
            const attachment = {
              type: getAttachmentType(fileToUpload.type),
              contentType: fileToUpload.type,
              key: response.data.key,
              filename: response.data.filename,
              thumbnailWidth: response.data.thumbnailWidth,
              ...(dimensions ? { ...dimensions } : {})
            };
            return [attachment];
          }
          return [];
        }
        return [];
      };
      const attachments = await getAttachments();

      if (internalOnly) {
        const payload = {
          userId: memberInfo.id,
          text: `[Internal only] ${message || 'User good to go'}`
        };
        sendInternalMessage(payload);
      } else {
        const payload = {
          userId: memberInfo.id,
          text: message,
          keeperId: memberInfo.keeper_id,
          email: memberInfo.email,
          attachments
        };
        sendMessage(payload);
      }
      dispatch(setChatScroll({ scroll: true, style: 'smooth' }));
    } catch (e) {
      defaultCaptureException(e);
      Swal.fire({ type: 'error', title: 'Oops...', text: e });
    } finally {
      setStagedFile(null);
      clear();
      setMessage('');
    }
  };

  return (
    <>
      <MacroSection setMacroMessage={setMacroMessage} />
      {stagedFile && (
        <ChatSectionFilePreview file={stagedFile} type={getAttachmentType(stagedFile?.type)} onClose={onClose} />
      )}
      <div className='chat-section-footer'>
        <IconButton onClick={openFileSelector}>
          <img alt='send' src={addIcon} style={{ filter: 'brightness(0)' }} />
        </IconButton>
        <TextField
          fullWidth
          variant='outlined'
          multiline
          onChange={(e) => setMessage(e.target.value)}
          placeholder='Reply to user'
          value={message}
          autoComplete='off'
          InputProps={{
            sx: { borderRadius: '12px' },
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  onClick={(e) => {
                    handleSendChatMessage(e, memberInfo.phone, false);
                  }}>
                  <img
                    alt='send'
                    src={message.length === 0 ? dismissIcon : sendIcon}
                    style={{ filter: 'brightness(0)' }}
                  />
                </IconButton>
                <IconButton
                  onClick={() => {
                    dispatch(setMacroModalStatus(true));
                  }}>
                  <img alt='macros' src={flashIcon} style={{ filter: 'brightness(0)' }} />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </div>
    </>
  );
};

export default ChatSectionFooter;
