import _ from 'lodash';
import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { serverUrl } from '../global/Environment';
import { logout } from '../actions/authActions';
import history from '../keeperHistory';

const baseUrl = serverUrl();

export const TAGS = {};

const QUERY_MAX_RETRIES = 3;

const baseQuery = fetchBaseQuery({
  baseUrl: `${baseUrl}api/web-ops`,
  prepareHeaders: (headers) => {
    headers.set('Authorization', localStorage.getItem('KeeperToken'));
    // Headers to prevent return of cached data
    headers.set('Cache-Control', 'no-store');
    headers.set('Pragma', 'no-cache');
    headers.set('Expires', '0');
  },
  timeout: 10_000
});

const isUnauthorized = (response) => response?.data?.msg === 'Unauthorized Request';

export const baseQueryWithAuth = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);

  if (isUnauthorized(result)) {
    await api.dispatch(logout());
    history.push('/');
  }

  return result;
};

const baseQueryWithRetry = retry(
  async (args, api, extraOptions) => {
    const result = await baseQuery(args, api, extraOptions);

    if (isUnauthorized(result)) {
      api.dispatch(logout());
      history.push('/');
      retry.fail(result.error, result.meta);
    }

    return result;
  },
  {
    maxRetries: QUERY_MAX_RETRIES
  }
);

const baseApi = createApi({
  refetchOnReconnect: true,
  refetchOnFocus: true,
  // Keep unused query data for 3 mins (this is how long cached query state is valid after removal of all subscriptions)
  keepUnusedDataFor: 3 * 60,
  baseQuery: baseQueryWithRetry,
  tagTypes: _.values(TAGS),
  endpoints: () => ({})
});

export default baseApi;
