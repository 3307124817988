import React from 'react';
import Tags from './Tags/Tags.jsx';
import { Modal } from '../../../../Common';
import UserTags from './Tags/UserTags.jsx';
import { connect } from 'react-redux';
import { setHasTagModal, setTagResMessage } from '../../../../../actions/tagActions';
import './TagSection.scss';
import { BOOKKEEPER_ACCOUNT_TYPES } from '../../../../../constants';

const TagSection = ({ tag: tagState, auth, setHasTagModal, setTagResMessage }) => {
  const isAdmin = auth?.user?.accountType === BOOKKEEPER_ACCOUNT_TYPES.ADMIN;

  function onModalOpen() {
    setHasTagModal(true);
    setTagResMessage({ successMsg: undefined, errorMsg: undefined });
  }

  return (
    <div className='tagSection'>
      <div className='header'>
        <h5>Tags</h5>{' '}
        {isAdmin && (
          <button type='button' className='btn tags' onClick={onModalOpen}>
            Edit Tags (Global)
          </button>
        )}
      </div>
      <UserTags />
      <Modal open={tagState.hasTagModal} size='big' handleClose={() => setHasTagModal(false)}>
        <Tags />
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  tag: state.tag,
  auth: state.auth
});

const ConnectedTagSection = connect(mapStateToProps, {
  setHasTagModal,
  setTagResMessage
})(TagSection);

export default ConnectedTagSection;
