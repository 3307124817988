import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import className from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import Swal from 'sweetalert2';
import { setAddExpenseView } from '../../../../actions/expenseActions';
import { setFilterStatus } from '../../../../actions/userActions';
import { setAutoPredict, setHold } from '../../../../services/userService';
import ChatSection from './ChatSection';
import './MessageSection.scss';
import MonitoredAccounts from './MonitoredAccounts';
import ImpersonationSection from './ImpersonationSection.jsx';
import { setRuleModalStatus } from '../../../../actions/ruleActions';
import moment from 'moment';
import IconRuleModal from '../../../../assets/img/RuleModal.png';
import IconAddExpense from '../../../../assets/img/AddExpense.png';
import { formatCurrency } from '../../../../global/Helpers';
import TagSection from './TagSection/TagSection.jsx';
import store from '../../../../store/store';
import { BOOKKEEPER_ACCOUNT_TYPES } from '../../../../constants';

class MessageSection extends Component {
  /**
   * @description Turn on or off auto predict
   * @param {Boolean} val
   */
  async setAutoPredict(val) {
    try {
      const {
        memberInfo: { phone }
      } = this.props.user;
      const reqObj = {
        phone,
        autoPredict: val
      };
      await this.props.setAutoPredict(reqObj);
    } catch (e) {
      Swal.fire({ type: 'error', title: 'Oops...', text: 'Error occured' });
    }
  }

  /**
   * @description Put this user on/off hold
   */
  async setHold() {
    try {
      const {
        memberInfo: { id, isOnHold }
      } = this.props.user;
      const reqObj = {
        id,
        holdingBookkeeperId: store.getState().auth.user.id,
        isOnHold: !isOnHold
      };
      await this.props.setHold(reqObj);
    } catch (e) {
      Swal.fire({ type: 'error', title: 'Oops...', text: 'Error occured' });
    }
  }

  setFilterStatus() {
    const { showFilters } = this.props.user;
    this.props.setFilterStatus(!showFilters);
  }

  render() {
    const { memberInfo, showFilters } = this.props.user;
    const auth = this?.props?.auth;
    const churned = memberInfo.welcomed === 1 && memberInfo.active === 0 ? ' - inactive' : '';
    const filterClass = className({
      filterMenu: true,
      show: showFilters ? true : false
    });
    const trialStart = memberInfo.subscribedAt
      ? moment(memberInfo.subscribedAt).format('YYYY-MM-DD')
      : 'data unavailable';
    const jobs = memberInfo.jobs && memberInfo.jobs.join(', ');
    // Sorts savings by year
    const savings = _.chain(this.props)
      .get(['expense', 'savings'])
      .thru(Object.entries)
      .sortBy('0')
      .value();
    const isExternalAccountant = auth?.user?.accountType === BOOKKEEPER_ACCOUNT_TYPES.EXTERNAL_ACCOUNTANT;

    return (
      <>
        <div className='messageSection'>
          <div className='userInfo'>
            <div className='leftSection'>
              <h6>
                {memberInfo.firstname}{' '}
                {memberInfo.pricing?.includes('premium') && <FontAwesomeIcon icon={['fa', 'star']} color='gold' />}
                {churned}
              </h6>
              <div className='workInfo'>
                <p>
                  <span className='jobs'>jobs: </span>
                  <span className='jobs'>{jobs}</span>
                </p>

                <div>
                  <div className='workIcon'>{memberInfo.car === 1 && <FontAwesomeIcon icon={['fa', 'car']} />}</div>
                  <div className='workIcon'>{memberInfo.home === 1 && <FontAwesomeIcon icon={['fa', 'home']} />}</div>
                  <div className='workIcon'>
                    {memberInfo.meals === 1 && <FontAwesomeIcon icon={['fa', 'utensils']} />}
                  </div>
                  <div className='workIcon'>
                    {memberInfo.travel === 1 && <FontAwesomeIcon icon={['fa', 'plane']} />}
                  </div>
                </div>
              </div>

              {memberInfo.work_trip_status === 'on work trip' && <p>Traveling</p>}
            </div>
            <div className='rightSection'>
              <div className='btn-group'>
                <button type='button' className='btn addRules' onClick={() => this.props.setRuleModalStatus(true)}>
                  <img src={IconRuleModal} width='32' alt='Rule Modal' />
                </button>
                <button type='button' className='btn addExpense' onClick={() => this.props.setAddExpenseView(true)}>
                  <img src={IconAddExpense} width='32' alt='Add Expense' />
                </button>
              </div>
              <ImpersonationSection />
            </div>
            {memberInfo.firstname && !isExternalAccountant && <TagSection />}
            {!isExternalAccountant && (
              <div className='userFilter'>
                <button type='button' onClick={() => this.setFilterStatus()}>
                  Additional Info{' '}
                  {!showFilters && (
                    <span>
                      <FontAwesomeIcon icon={['fa', 'angle-right']} />
                    </span>
                  )}
                  {showFilters && (
                    <span>
                      <FontAwesomeIcon icon={['fa', 'angle-down']} />
                    </span>
                  )}
                </button>
              </div>
            )}
            <div className={filterClass}>
              <div className='autoPredict'>
                <label>Auto Predict</label>
                {memberInfo.autoPredict && (
                  <button type='button' onClick={() => this.setAutoPredict(false)}>
                    <FontAwesomeIcon icon={['fa', 'toggle-on']} />
                  </button>
                )}
                {!memberInfo.autoPredict && (
                  <button type='button' onClick={() => this.setAutoPredict(true)}>
                    <FontAwesomeIcon icon={['fa', 'toggle-off']} />
                  </button>
                )}
              </div>
              <div className='autoPredict'>
                <label>Hold</label>
                {memberInfo.isOnHold && (
                  <button type='button' onClick={() => this.setHold(false)}>
                    <FontAwesomeIcon icon={['fa', 'toggle-on']} />
                  </button>
                )}
                {!memberInfo.isOnHold && (
                  <button type='button' onClick={() => this.setHold(true)}>
                    <FontAwesomeIcon icon={['fa', 'toggle-off']} />
                  </button>
                )}
              </div>
              <div className='autoPredict'>
                {memberInfo.qdeckUrl ? (
                  <label>
                    <a href={memberInfo.qdeckUrl} target='_blank' rel='noopener noreferrer'>
                      User ID:&nbsp;
                    </a>
                    {memberInfo.id}
                  </label>
                ) : (
                  <label>
                    User Id: {memberInfo.id} <span className='transparent'>/</span>
                  </label>
                )}
              </div>
              <div className='autoPredict'>
                <label>Email: </label>
                <label>
                  <a href={`mailto:${memberInfo.email}`} target='_blank' rel='noopener noreferrer'>
                    {memberInfo.email}
                  </a>
                </label>
              </div>
              <div className='autoPredict'>
                <label>Messaging Platform: </label>
                <label>{memberInfo.platform}</label>
              </div>
              <div className='autoPredict'>
                <label>User Type: </label>
                <label>
                  {memberInfo.referrer || 'default'} - {memberInfo.pricing || 'null'}
                </label>
              </div>
              {savings.map(([year, savings]) => (
                <div className='autoPredict' key={year}>
                  <label>{year} Savings: </label>
                  <label>{formatCurrency(savings)} </label>
                </div>
              ))}
              <div className='bookkeeper'>
                <label>Trial start: </label>
                <label>{trialStart}</label>
              </div>
              <div className='bookkeeper'>
                <label>Tax rate: </label>
                <label>{memberInfo.tax_rate}%</label>
              </div>
              {/* Monitored account section */}
              <MonitoredAccounts />
            </div>
          </div>
          <ChatSection isExternalAccountant={isExternalAccountant} />
        </div>
      </>
    );
  }
}

MessageSection.propTypes = {
  setAddExpenseView: PropTypes.func.isRequired,
  setRuleModalStatus: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  expense: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  user: state.user,
  expense: state.expense,
  auth: state.auth
});

const ConnectedMessageSection = connect(mapStateToProps, {
  setAddExpenseView,
  setAutoPredict,
  setFilterStatus,
  setRuleModalStatus,
  setHold
})(MessageSection);

export default ConnectedMessageSection;
