import baseApi from '../api/baseApi';
import { trackActivity } from '../utils/analytics/analyticsService';
import defaultCaptureException from '../utils/sentryUtils';

// Api
const messageApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    sendInternalMessage: builder.mutation({
      query: ({ userId, text }) => ({
        url: `message/send-internal-message`,
        method: 'POST',
        body: { user_id: userId, text }
      }),
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const { userId, text } = arg;
          trackActivity('webops: internal message sent', { userId, text });
          await queryFulfilled;
        } catch (e) {
          defaultCaptureException(e);
        }
      }
    }),
    sendStreamMessage: builder.mutation({
      query: ({ userId, text, keeperId, attachments, email }) => ({
        url: `message/send-stream-message`,
        method: 'POST',
        body: { id: userId, text, keeper_id: keeperId, attachments, email }
      }),
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const { userId, text, keeperId, email } = arg;
          trackActivity('webops: message sent to user', { userId, text, keeperId, email });
          await queryFulfilled;
        } catch (e) {
          defaultCaptureException(e);
        }
      }
    }),
    uploadFile: builder.mutation({
      query: (file) => ({
        url: `message/files`,
        method: 'POST',
        body: file
      }),
      transformResponse: (response) => response.data,
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const { type, user_id: userId } = arg;
          trackActivity('webops: upload file', { type, userId });
          await queryFulfilled;
        } catch (e) {
          defaultCaptureException(e);
        }
      }
    })
  })
});

export const { useSendInternalMessageMutation, useUploadFileMutation, useSendStreamMessageMutation } = messageApi;
