import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import axios from 'axios';
import _ from 'lodash';
// Redux init
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/browser';
// Get Store init
import store from './store/store';
// Util
import { initFonts, setAuthToken } from './utils';
import routes from './routes';
import { setCurrentUser, logout } from './actions/authActions';
import jwt_decode from 'jwt-decode';
// Components
import { PageNotFound, PrivateRoute } from './Components/Common';
// Global CSS
import './App.scss';
import { setUser } from './utils/analytics/analyticsService';
import { connectSocket } from './actions/socketActions';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import history from './keeperHistory';

// Init Font Awesome icons
initFonts();

// Mange Authentication
if (localStorage.KeeperToken) {
  /**
   * Set auth token header auth
   */
  const token = localStorage.KeeperToken;
  /**
   * Decode token and get user info and exp
   * Set user and isAuthenticated
   **/
  const decoded = jwt_decode(token);
  store.dispatch(setCurrentUser(decoded));
  /**
   * Check for expired token
   */
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    /**
     * Logout user
     */
    store.dispatch(logout());
    /**
     * Clear current Profile
     * Redirect to login
     */
    window.location.href = '/';
  } else {
    setUser(decoded);
    setAuthToken(token);
    store.dispatch(connectSocket());
    Sentry.setUser({ id: decoded.id, email: decoded.email });
  }
}

// Add a response interceptor
axios.interceptors.response.use(function(response) {
  const msg = _.get(response, ['data', 'msg']);

  if (msg === 'Unauthorized Request') {
    store.dispatch(logout());
  } else {
    return response;
  }
});

const App = () => {
  const routeComponents = routes.map((r, i) =>
    r.private ? <PrivateRoute key={i} {...r} /> : <Route key={i} {...r} />
  );

  return (
    <Provider store={store}>
      <Router history={history}>
        <div>
          <Switch>
            {routeComponents}
            <Route exact component={PageNotFound} />
          </Switch>
        </div>
      </Router>
    </Provider>
  );
};

export default App;
