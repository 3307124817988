import './index.scss';
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App.jsx';
import * as Sentry from '@sentry/browser';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GOOGLE_LOGIN_CLIENT_ID } from './global/Environment';

if (process.env.REACT_APP_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://249d62d90acf49c29eb63a64c5b38eee@o307744.ingest.sentry.io/4504489023832064',
    environment: process.env.REACT_APP_ENV
  });
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <GoogleOAuthProvider clientId={GOOGLE_LOGIN_CLIENT_ID}>
    <App />
  </GoogleOAuthProvider>
);
